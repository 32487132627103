import React, { useContext, useEffect } from 'react'
import Header from '../Templates/Header'
import { useNavigate } from 'react-router-dom';
import { userContext } from '../../App'
import { useState } from 'react';
import SignIn from './SignIn';
import Register from './Register';
import ForgotPassword from './ForgotPassword';
import { Feather_0, Github_Logo, Linkedin_Logo } from '../Templates/ImageImports';
import Footer from '../Templates/Footer';
function Login() {
    let { login } = useContext(userContext)
    let navigate = useNavigate()
    useEffect(() => {
        if (login.user) {
            navigate("/")
        }
    })
    let [toggleLoginPage, updateToggleLoginPage] = useState(0)
    function updateLoginType(pageNo) {
        updateToggleLoginPage(pageNo)
    }
    return (

        <div id="loginPageContainer">
            <Header />
            <div className="loginFormContainer">
                {
                    toggleLoginPage === 0 ? (
                        <>
                            <div className="SignInInformationContainer">
                                <div className='imageContainer'>
                                    <img src={Feather_0} alt="" />
                                </div>
                                <h1>Welcome to <span className="darkblue_color">Shree Krishna Technology</span></h1>
                                <h5>
                                    Shree Krishna Technology, This site is created to work in all the development work in multiple engineering disciplines
                                </h5>
                                <h5>Social Links:</h5>
                                <div className="SocialLinks">
                                    <img src={Github_Logo} alt="" />
                                    <img src={Linkedin_Logo} alt="" />
                                </div>
                            </div>
                            <SignIn changeLoginType={updateLoginType} />
                        </>
                    ) : (
                        <>
                            {
                                toggleLoginPage === 1 ? (
                                    <>
                                        <Register changeLoginType={updateLoginType} />
                                        <div className="SignInInformationContainer">
                                            <div className='imageContainer'>
                                                <img src={Feather_0} alt="" />
                                            </div>
                                            <h1>Welcome to <span className="darkblue_color">Shree Krishna Technology</span></h1>
                                            <h5>
                                                Shree Krishna Technology, This site is created to work in all the development work in multiple engineering disciplines
                                            </h5>
                                            <h5>Social Links:</h5>
                                            <div className="SocialLinks">
                                                <img src={Github_Logo} alt="" />
                                                <img src={Linkedin_Logo} alt="" />
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <ForgotPassword changeLoginType={updateLoginType} />
                                        <div className="SignInInformationContainer">
                                            <div className='imageContainer'>
                                                <img src={Feather_0} alt="" />
                                            </div>
                                            <h1>Welcome to <span className="darkblue_color">Shree Krishna Technology</span></h1>
                                            <h5>
                                                Shree Krishna Technology, This site is created to work in all the development work in multiple engineering disciplines
                                            </h5>
                                            <h5>Social Links:</h5>
                                            <div className="SocialLinks">
                                                <img src={Github_Logo} alt="" />
                                                <img src={Linkedin_Logo} alt="" />
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </>
                    )
                }
            </div>
            <Footer/>
        </div>
    )
}

export default Login