import React from 'react'
import { NavLink } from 'react-router-dom'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import YouTubeIcon from '@mui/icons-material/YouTube';
function Footer() {
    return (
        <>
            <footer>
                <div className="footerOptions">
                    <div className="smallIntro">
                        <h1>Shree Krishna Technology</h1>
                        <p>We are a Engineering application development Organization and we focus on Finding solutions for Different Engineering Problems. Our aim is to build advanced systems that will helpful for indeed ones.
                        </p>
                        <div className="links">
                            <NavLink to="/"><LinkedInIcon /></NavLink>
                            <NavLink to="/"><GitHubIcon /></NavLink>
                            <NavLink to="/"><YouTubeIcon /></NavLink>
                        </div>
                    </div>
                    <div className="option connectDetails">
                        <h4>Connect With Us</h4>
                        <li><NavLink to="/suggestions">Give Suggestions</NavLink></li>
                        <li><NavLink to="/complaints">Register Complaint</NavLink></li>
                    </div>
                    <div className="option contactDetails">
                        <h4>Contact Us</h4>
                        <h5>Get in touch with us!</h5>
                        <li><a href="mailto:info.shreekrishnatechnology@gmail.com">Email: <span>info.shreekrishnatechnology@gmail.com</span></a></li>
                        <li><a href="tel:+91xxxxxxxxxx">Phone: <span>+91xxxxxxxxxx</span></a></li>
                    </div>
                </div>
                <div className="bottomTermsLink">
                    <NavLink to="/terms">Terms and Conditions</NavLink>
                    <NavLink to="/datapolicy">Data Policies</NavLink>
                </div>
                <hr />
                <div className="footerBottom">
                    <h3>Shree Krishna Technology || All Rights are Reserved</h3>
                </div>
            </footer>
        </>
    )
}

export default Footer