import React from 'react';
import Footer from '../Templates/Footer';
import Header from '../Templates/Header';
// import { Home_background_0,Home_background_2,Home_background_1,Home_background_3,Home_background_4,Home_background_5} from '../Templates/ImageImports';
function Home() {
    return (
        <>
            <div id="homePageContainer">
                <section className="landingPageImage">
                    <Header />
                    <div className="mainHomePageContainer">
                        <h1>
                            All in one Engineering Problem's Solution
                        </h1>
                        <h2>Any Problem, Engineering Solution</h2>
                        <h3>
                            We are a Engineering application development Organization and we focus on Finding solutions for Different Engineering Problems. Our aim is to build advanced systems that will helpful for indeed ones.
                        </h3>
                    </div>
                    <div className="bubble bubble1"></div>
                    <div className="bubble bubble2"></div>
                    <div className="bubble bubble3"></div>
                    <div className="bubble bubble4"></div>
                    <div className="bubble bubble5"></div>
                </section>
                <div className="servicesContainer">
                    <div className="homepageHeadings">
                        <h1>Services</h1>
                    </div>
                    <h1>Software Services</h1>
                    <div className="homepageServices">
                        <div className="serviceCard">
                            <img src="https://www.pngfind.com/pngs/m/493-4933828_software-development-clipart-identity-programming-icon-png-transparent.png" alt="" />
                            <h1>Software Development</h1>
                        </div>
                        <div className="serviceCard">
                            <img src="https://cdn-icons-png.flaticon.com/512/2210/2210153.png" alt="" />
                            <h1>Web Development</h1>
                        </div>
                        <div className="serviceCard">
                            <img src="https://cdn-icons-png.flaticon.com/512/6470/6470993.png" alt="" />
                            <h1>App Development</h1>
                        </div>
                        <div className="serviceCard">
                            <img src="https://cdn-icons-png.flaticon.com/512/6470/6470993.png" alt="" />
                            <h1>Business Development</h1>
                        </div>
                        <div className="serviceCard">
                            <img src="https://cdn-icons-png.flaticon.com/512/2464/2464176.png" alt="" />
                            <h1>Machine learning</h1>
                        </div>
                        <div className="serviceCard">
                            <img src="https://cdn-icons-png.flaticon.com/512/2103/2103832.png" alt="" />
                            <h1>Deep Leaning</h1>
                        </div>
                        <div className="serviceCard">
                            <img src="https://icons.veryicon.com/png/o/miscellaneous/color-work-icon/natural-language-processing.png" alt="" />
                            <h1>Natural Language Processing</h1>
                        </div>
                    </div>
                    <h1>Hardware Services</h1>
                    <div className="homepageServices">
                        <div className="serviceCard">
                            <img src="https://www.speranzainc.com/wp-content/uploads/2021/06/internet-of-things-400x400.png" alt="" />
                            <h1>IOT Development</h1>
                        </div>
                        <div className="serviceCard">
                            <img src="https://media.istockphoto.com/id/1227080187/vector/building-with-gear-and-engineer-thin-line-icon-smart-home-concept-smart-house-repair-worker.jpg?s=612x612&w=0&k=20&c=CA3Ys36r9Xy0S7ybGlen0ApPwpUj1OMcd8RqnxMuT7w=" alt="" />
                            <h1>Home Appliances Repairing</h1>
                        </div>
                    </div>
                </div>
                <div className="fieldOfWork">
                    <div className="homepageHeadings">
                        <h1>Fields of Work</h1>
                    </div>
                    <div className="fieldsContainer">
                        <div className="homepageFields">

                            <div className="fieldCard">
                                <img src="https://cdn.mos.cms.futurecdn.net/steVjWGx3vYPjMpJL2jVcV-1200-80.jpg" alt="" />
                                <h1>Engineering</h1>
                            </div>
                            <div className="fieldCard">
                                <img src="https://assets.thehansindia.com/h-upload/2020/04/04/959255-agriculture.webp" alt="" />
                                <h1>Agriculture</h1>
                            </div>
                            <div className="fieldCard">
                                <img src="https://www.pngitem.com/pimgs/m/113-1138414_computer-science-png-png-download-computer-science-icon.png" alt="" />
                                <h1>Computer Science</h1>
                            </div>
                            <div className="fieldCard">
                                <img src="https://www.technoduce.com/images/full-stack-web-development-logo.png" alt="" />
                                <h1>Full Stack Development</h1>
                            </div>
                            <div className="fieldCard">
                                <img src="https://cdn-icons-png.flaticon.com/512/4108/4108969.png" alt="" />
                                <h1>Data Science</h1>
                            </div>
                            <div className="fieldCard">
                                <img src="https://i.pinimg.com/550x/a4/73/45/a47345c7bcff5c8be7240a2574058dc6.jpg" alt="" />
                                <h1>Internet of Things</h1>
                            </div>
                            <div className="fieldCard">
                                <img src="https://www.esa.int/var/esa/storage/images/esa_multimedia/images/2010/04/electrical_component/9636951-4-eng-GB/Electrical_component_pillars.jpg" alt="" />
                                <h1>Electrical</h1>
                            </div>
                        </div>



                    </div>



                </div>
                <div className="techStack">
                    <div className="homepageHeadings">
                        <h1>Tech Stack</h1>
                    </div>
                </div>
                <div className="educationStack">
                    <div className="homepageHeadings">
                        <h1>Education Stack</h1>
                    </div>
                </div>
                <div className="contactFormContainer">

                </div>
                <Footer />
            </div>
        </>
    )
}

export default Home